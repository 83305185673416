.licensePlate_provinces_Box{
    width: 580px;
    height:270px;
    background-color: #d0d4da;
    margin: 0 auto;
    bottom: 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.licensePlate_provincesTist{
    flex:55px;
    height: 65px;
    line-height: 65px;
    margin: 0 auto;
    text-align: center;
    color:#ffffff;
    background-color: #22384b;
    border-top: 1px solid #abb2bd;
    border-left: 1px solid #abb2bd;
    cursor:pointer;
}

.licensePlate_provincesTist:nth-child(6n){
    border-right: 1px solid #abb2bd;
}


.licensePlate_letter_Box{
    width: 580px;
    height:270px;
    background-color: #d0d4da;
    margin: 0 auto;
    bottom: 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.licensePlate_letterTist{
    flex:55px;
    height: 88px;
    line-height: 88px;
    margin: 0 auto;
    text-align: center;
    color:#ffffff;
    background-color:#22384b;
    border-top: 1px solid #abb2bd;
    border-left: 1px solid #abb2bd;
    cursor:pointer;
}

.licensePlate_letterTist:nth-child(6n){
    border-right: 1px solid #abb2bd;
}


.licensePlate_digital_Box{
    width: 580px;
    height:270px;
    background-color: #d0d4da;
    margin: 0 auto;
    bottom: 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.licensePlate_digitalTist{
    flex:120px;
    height: 88px;
    line-height: 88px;
    margin: 0 auto;
    text-align: center;
    color:#ffffff;
    background-color:#22384b;
    border-top: 1px solid #abb2bd;
    border-left: 1px solid #abb2bd;
    cursor:pointer;
}