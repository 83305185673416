.main {
    width: 100%;
    height: 100%;
}

.rectifyButton {
    position: fixed;
    top: 20px;
    margin: 0 30vw;
    width: 20vw;
    height: 50px;
    background: #154e7f;
    border-radius: 10px;
    z-index: 1000;
    box-shadow: 6px 6px 12px #bebebe,
    -6px -6px 12px #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
}

.mainHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.searchContent {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
}

.searchButton {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@keyframes shrink {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

.carLine {
    display: flex;
    flex-direction: row;
}

.carBlock {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #6c7080;
    display: grid;
    place-items: center;
    background-color: #0e2774;
    color:#fff;
    margin-right: 5px;
    cursor:cell;
}

.carDotBlock{
    width: 10px;
    height: 40px;
    display: grid;
    place-items: center;
    margin-right: 5px;
}

.carDot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #273a6e;
}

.newPowerBlock{
    width: 50px;
    height: 40px;
    border-radius: 5px;
    border: 1px dashed #0f1424;
    display: grid;
    place-items: center;
    background-color: #b5c0e3;
    color: #2e33c6;
    font-size: 10px;
}