


/*.home-header {*/
/*    height: 53px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    padding: 0 10px;*/
/*    background-color: #fff;*/
/*}*/

/*.home-header-left {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    height: 100%;*/
/*}*/

/*.home-header-logo {*/
/*    width: 40px;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin: 0 10px 0 0;*/
/*}*/

/*.home-header-right {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    align-items: center;*/
/*    color: #ffffff;*/
/*}*/
/*.home-link {*/
/*    height: 100%;*/
/*    width: 48px;*/
/*    color: #ffffff;*/
/*    margin: 0 10px;*/
/*    font-weight: 600;*/
/*}*/
/*.home-body {*/
/*    width: 100%;*/
/*    !* height: 100%; *!*/
/*    !* display: flex; *!*/
/*    !* flex-direction: column; *!*/
/*    !* flex-direction: row; *!*/
/*    flex: 1;*/
/*}*/
/*.home-body-header {*/
/*    height:53px;*/
/*    width: 100%;*/
/*    background-color: #f3f2f1;*/
/*}*/

/*.home-card-group {*/
/*    width: 400px;*/
/*    height: 100%;*/
/*}*/

/*!* .home-card-group-header {*/
/*    height:168px;*/
/*    width: 100%;*/
/*} *!*/
/*.home-card-group-list {*/
/*    padding: 0 0 0 10px;*/
/*}*/

/*.home-todo {*/
/*    flex: 1;*/
/*    height: 100%;*/
/*    flex-direction: column;*/
/*    !* padding: 0 10px; *!*/
/*    border-right: 1px solid #f3f2f1;*/
/*}*/

/*.home-todo-header {*/
/*    height:168px;*/
/*    width: 100%;*/
/*    margin: 0 0 10px 0;*/
/*    background-color: #f3f2f1;*/
/*}*/
/*.home-todo-tab {*/
/*    !* padding: 0 10px; *!*/
/*}*/

/*.todo-list {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin: 0 0 10px 0;*/
/*}*/
/*.todo-list-item {*/
/*    height: 32px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    padding: 0 10px;*/
/*    margin: 0 0 5px 0;*/
/*    border-radius: 3px;*/
/*}*/
/*.todo-list-item:hover {*/
/*    background-color: #faf9f8;*/
/*    box-shadow:  5px 5px 6px #ededed, */
/*             -5px -5px 6px #ffffff;*/
/*    cursor: pointer;*/
/*    border-radius: 3px;*/
/*}*/
/*.todo-pagination {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-end;*/
/*}*/
/*.ant-tabs-tab {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
/*.ant-card-body {*/
/*    background-color: #faf9f8;*/
/*}*/
.chgh{
    display: flex;
    justify-content: center;
    align-items: center;
}